<template>
  <div>
    <b-card class="line-card custom-card">
      <b-row class="flex-nowrap d-flex align-items-center">
        <b-col cols="auto">
          <b-form-group class="mb-0" style="min-width: 300px">
            <LineSelect v-model="datasourceSelected" />
          </b-form-group>
        </b-col>
        <b-col cols="auto">
          <option-chart :label="$t('option_chart')" :showScale="true" :shape="true" :options="typeList" :typeList="typeList" :nameComponent="$options.name" />
        </b-col>
        <b-col cols="auto" class="ml-auto d-flex align-items-center">
          <SummaryData :chartData="chartData" />
          <Downloader :downloadImage="downloadImage" :downloadHtml="downloadHtml" :downloadCsv="downloadCsv" />
        </b-col>
      </b-row>
    </b-card>
    <LineChart ref="chart" :showYear="showYear" :nameComponent="$options.name" />
    <Timeplayer ref="timeplayer" v-model="date" :liveMode="liveMode" :speedMax="12" :speedValue="speed" @speedInput="speed = $event" :moveToEnd="moveToEnd" />
  </div>
</template>

<script>
const _ = require('lodash');
import LineSelect from '../../common/DatasourceSelect/LineSelect.vue';
import Downloader from '../../common/Downloader.vue';
import RadioGroup from '../../common/RadioGroup.vue';
import SummaryData from '../../common/SummaryData.vue';
import Timeplayer from '../../common/Timeplayer.vue';
import OptionChart from '../../common/OptionChartPlotly.vue';
import PausePlayerMixin from '@/mixins/PausePlayerMixin';
import ScaleMixin from '@/mixins/ScaleMixin';
import ChartYearMixin from '@/mixins/ChartYearMixin';
import { SelectedGroupMixin } from '@/mixins/GroupItemsMixin';
import LineChart from './LineChartComponent.vue';
import i18n from '@/libs/i18n';
import { OPTION_PLOTLY_PALETTE } from '@/constants/colors';
import { scaleValueLog10, scaleValueLog10Plus, scaleValueNormalize, scaleValueStandardize } from '@/utilities/NumberUtility.js';
const TYPE_LIST = [
  { value: 'lines', text: 'lines_title', icon: require('/static/images/timeseries/lines.svg').default },
  { value: 'markers', text: 'markers_title', icon: require('/static/images/timeseries/markers.svg').default },
  { value: 'lines+markers', text: 'lines_markers_title', icon: require('/static/images/timeseries/lines+markers.svg').default },
];
export default {
  name: 'lines',
  components: { LineSelect, LineChart, Timeplayer, SummaryData, RadioGroup, Downloader, OptionChart },
  mixins: [PausePlayerMixin, ScaleMixin, ChartYearMixin, SelectedGroupMixin],
  async mounted() {
    let { data, layout } = await this.getLayoutAndData();
    this.$refs.chart.createChart(data, layout, i18n.locale);
  },
  data() {
    return {
      typeList: TYPE_LIST,
      speed: 9,
      datasourceExtended: [],
      chartData: [],
      moveToEnd: 0,
      isAxisTime: false,
    };
  },
  computed: {
    name() {
      return this.$options.name;
    },
    typeSelected() {
      return this.$store.state.tabs[this.$options.name].typeSelected;
    },
    datasources() {
      return this.$store.state.datasource.datasources;
    },
    showYear() {
      return this.$store.state.tabs[this.$options.name].year;
    },
    showLegend() {
      return this.$store.state.tabs[this.$options.name].legend;
    },
    markerColor() {
      let colors = this.$store.state.tabs[this.$options.name].colors;
      colors = colors && colors.length > 0 ? colors : OPTION_PLOTLY_PALETTE.D3;
      return colors;
    },
    markerSymbol() {
      return this.$store.state.tabs[this.$options.name].symbolSelected;
    },
    sizeWidth() {
      return this.$store.state.tabs[this.$options.name].sizeWidth;
    },
    datasourceSelected: {
      get() {
        return this.$store.state.tabs[this.$options.name].datasourceSelected;
      },
      set(datasourceSelected) {
        this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { datasourceSelected });
      },
    },
    date: {
      get() {
        return this.$store.state.tabs[this.$options.name].date;
      },
      set(date) {
        this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { date });
      },
    },
    tempProject() {
      return this.$store.state.ecoplot.tempProject;
    },
    locale() {
      return i18n.locale;
    },
    rotateChart() {
      return this.$store.state.tabs[this.$options.name].rotate;
    },
    editable() {
      return this.$store.state.tabs[this.$options.name].editable;
    },
    outlineGroup() {
      return this.$store.state.tabs[this.$options.name].outlineGroup;
    },
    liveMode() {
      try {
        let check = false;
        if (!this.datasources || !this.datasourceSelected) return false;
        let arryIdDatasources = [];
        this.datasources.map((data) => {
          arryIdDatasources.push(data.id);
        });
        arryIdDatasources = _.unionBy(arryIdDatasources);
        this.datasourceSelected.map((data) => {
          let splitX = data.x ? data.x.split(' : ') : null;
          const idX = splitX ? splitX[0] : null;
          let splitY = data.y ? data.y.split(' : ') : null;
          const idY = splitY ? splitY[0] : null;
          if (idX && idY) {
            let indexDataX = arryIdDatasources.indexOf(idX);
            let indexDataY = arryIdDatasources.indexOf(idY);
            if ((indexDataX > -1 && this.datasources[indexDataX].live) || (indexDataY > -1 && this.datasources[indexDataY].live)) {
              check = true;
              return check;
            }
          }
        });
        return check;
      } catch {}
      return false;
    },
    countLiveMode() {
      return this.$store.state.settings.countLiveMode;
    },
  },
  watch: {
    async editable() {
      let { data, layout } = await this.getLayoutAndData();
      this.$refs.chart.createChart(data, layout, i18n.locale);
    },
    async rotateChart() {
      let { data, layout } = await this.getLayoutAndData();
      if (data.length > 0) {
        this.$refs.chart.createChart(data, layout, i18n.locale);
      }
    },
    selectedGroup: {
      deep: true,
      async handler() {
        try {
          let { data, layout } = await this.getLayoutAndData();
          this.$refs.chart.updateChart(data, layout, i18n.locale);
        } catch {}
      },
    },
    sizeWidth: {
      deep: true,
      async handler() {
        try {
          let { data, layout } = await this.getLayoutAndData();
          this.$refs.chart.updateChart(data, layout, i18n.locale);
        } catch {}
      },
    },
    async outlineGroup() {
      try {
        let { data, layout } = await this.getLayoutAndData();
        this.$refs.chart.updateChart(data, layout, i18n.locale);
      } catch {}
    },
    async markerSymbol() {
      try {
        let { data, layout } = await this.getLayoutAndData();
        this.$refs.chart.updateChart(data, layout, i18n.locale);
      } catch {}
    },
    async markerColor() {
      try {
        let { data, layout } = await this.getLayoutAndData();
        this.$refs.chart.updateChart(data, layout, i18n.locale);
      } catch {}
    },
    async showLegend() {
      try {
        let { data, layout } = await this.getLayoutAndData();
        this.$refs.chart.updateChart(data, layout, i18n.locale);
      } catch {}
    },
    datasourceSelected() {
      try {
        let datasourceExtended = [];
        this.datasourceSelected.forEach((datasourceSelected) => {
          if (!datasourceSelected.x || !datasourceSelected.y) return;
          let [dX, iX] = datasourceSelected.x.split(' : ');
          let [dY, iY] = datasourceSelected.y.split(' : ');

          let dXTemp = [dX];
          let dYTemp = [dY];
          if (iX == 'TIME  ⏰') {
            dXTemp = dX.split('|');
          }
          if (iY == 'TIME  ⏰') {
            dYTemp = dY.split('|');
          }
          if (!this.$db[dXTemp[0]] || !this.$db[dYTemp[0]]) return;
          if (dX === dY || iX == 'TIME  ⏰' || iY == 'TIME  ⏰') {
            // prettier-ignore
            let locationsX, locationsY, locationsIntersect;
            locationsX = Object.keys(this.$db[dXTemp[0]].columns)
              .filter((pair) => pair.endsWith(`*${iX}`))
              .map((pair) => pair.split('*')[0]);
            locationsY = Object.keys(this.$db[dYTemp[0]].columns)
              .filter((pair) => pair.endsWith(`*${iY}`))
              .map((pair) => pair.split('*')[0]);

            if (locationsX.length != 0) {
              if (locationsY.length == 0) {
                locationsIntersect = locationsX;
              } else {
                locationsIntersect = _.intersection(locationsX, locationsY); // giao diem cua 2 phan
              }
            } else {
              if (locationsY.length == 0) {
                let locationsDate = [];
                let dXTime = [];
                let dYTIme = [];
                if (dXTemp.length > 1) {
                  for (let k = 0; k < dXTemp.length - 1; k++) {
                    let firstNameData = Object.keys(this.$db[dXTemp[k]].items)[0];
                    dXTime.push(firstNameData);
                    dYTIme.push(dXTemp[k]);
                    let locationsDateTemp = Object.keys(this.$db[dXTemp[k]].columns)
                      .filter((pair) => pair.endsWith(`*${firstNameData}`))
                      .map((pair) => pair.split('*')[0]);
                    locationsDate.push(locationsDateTemp);
                  }
                  locationsIntersect = locationsDate; // 2 axis = timer
                  dX = dXTime;
                  dY = dYTIme;
                }
              } else {
                locationsIntersect = locationsY;
              }
            }
            datasourceExtended.push({ dX, iX, dY, iY, locations: locationsIntersect });
          }
        });
        this.datasourceExtended = datasourceExtended;
        this.moveToEnd++;
      } catch {}
    },
    async datasourceExtended() {
      this.initFirstTime = true;
    },
    async scale() {
      try {
        let { data, layout } = await this.getLayoutAndData();
        this.$refs.chart.updateChart(data, layout, i18n.locale);
      } catch {}
    },
    async date() {
      try {
        if (this.initFirstTime) {
          let { data, layout } = await this.getLayoutAndData(true);
          this.$refs.chart.createChart(data, layout, i18n.locale);
        } else {
          let { data, layout } = await this.getLayoutAndData();
          if (this.isAxisTime) {
            this.$refs.chart.updateChart(data, layout, i18n.locale);
          } else {
            this.$refs.chart.animateChart(data, this.speed);
          }
        }
      } catch {}
    },
    async typeSelected() {
      try {
        let { data, layout } = await this.getLayoutAndData();
        this.$refs.chart.updateChart(data, layout, i18n.locale);
      } catch {}
    },
    async locale() {
      try {
        let { data, layout } = await this.getLayoutAndData();
        if (data.length > 0) {
          this.$refs.chart.updateChart(data, layout, i18n.locale);
        }
      } catch {}
    },
    async countLiveMode() {
      try {
        let { data, layout } = await this.getLayoutAndData();
        if (data.length > 0) {
          this.$refs.chart.updateChart(data, layout, i18n.locale);
        }
      } catch {}
    },
  },
  methods: {
    async getData() {
      try {
        let data = [];
        let vData = [];
        let traceCount = 0;
        let lineWidth = null;
        let markerSize = null;
        let oulineMarker = {};
        if (this.sizeWidth.default == true) {
          lineWidth = Number(this.sizeWidth.width);
          markerSize = Number(this.sizeWidth.size);
        }
        if (this.outlineGroup.default == true) {
          oulineMarker = {
            color: this.changeColorAlpha(this.outlineGroup.color, Number(this.outlineGroup.opacity)),
            width: this.outlineGroup.width,
          };
        }
        const minDate = this.date.length === 2 ? this.date[0] : this.date.length === 1 ? '0000-00-00 00:00:00' : '';
        const maxDate = this.date.length === 2 ? this.date[1] : this.date.length === 1 ? this.date[0] : '';
        for (const { dX, iX, dY, iY, locations } of this.datasourceExtended) {
          this.initFirstTime = false;
          let symbol = null;
          if (iX === 'TIME  ⏰') {
            //  x - timer , y-value
            this.isAxisTime = true;
            if (iY !== 'TIME  ⏰') {
              const locationsItemsY = _.map(locations, (l) => l + '-' + iY);
              const recordsY = await this.selectRangeByLocationsItems(dY, locationsItemsY, minDate, maxDate, this.scale);
              const x = recordsY.dates;
              locations.forEach((location) => {
                const locationItemY = location + '-' + iY;
                const arrObjectsY = recordsY[locationItemY];
                const y = arrObjectsY ? arrObjectsY : [];
                vData = vData.concat(y);
                const visible = this.selectedGroup && Object.keys(this.selectedGroup).length !== 0 && !this.selectedGroup.items.includes(location) ? 'legendonly' : true;
                symbol = this.typeSelected == 'lines' || this.markerColor.length <= 0 ? null : this.markerSymbol[Math.floor(traceCount / this.markerColor.length) % this.markerSymbol.length];
                data.push({ name: locationItemY, visible, x, y, mode: this.typeSelected, hovertemplate: `(x:%{x}, y:%{y})`, line: { width: lineWidth }, marker: { size: markerSize, symbol: symbol, color: this.markerColor[traceCount % this.markerColor.length], line: oulineMarker } });
                traceCount++;
              });
            } else {
              if (dX.length > 0) {
                for (let intdX = 0; intdX < dX.length; intdX++) {
                  //  x - timer , y-timer
                  const locationsItemsX = _.map(locations[intdX], (l) => l + '-' + dX[intdX]);
                  const recordsDate = await this.selectRangeByLocationsItems(dY[intdX], locationsItemsX, minDate, maxDate, this.scale);
                  let dates = recordsDate.dates;
                  this.initFirstTime = false;
                  const visible = this.selectedGroup && Object.keys(this.selectedGroup).length !== 0 && !this.selectedGroup.items.includes(location) ? 'legendonly' : true;
                  symbol = this.typeSelected == 'lines' || this.markerColor.length <= 0 ? null : this.markerSymbol[Math.floor(traceCount / this.markerColor.length) % this.markerSymbol.length];
                  let nameData = this.$t('time') + '  ⏰';
                  data.push({ name: nameData, visible, x: dates, y: dates, mode: this.typeSelected, line: { width: lineWidth }, marker: { size: markerSize, symbol: symbol, color: this.markerColor[traceCount % this.markerColor.length], line: oulineMarker } });
                  traceCount++;
                }
              }
            }
          } else {
            if (iY !== 'TIME  ⏰') {
              //  x - value , y-value
              this.isAxisTime = false;
              const locationsItemsX = _.map(locations, (l) => l + '-' + iX);
              const recordsX = await this.selectRangeByLocationsItems(dX, locationsItemsX, minDate, maxDate, this.scale);
              const locationsItemsY = _.map(locations, (l) => l + '-' + iY);
              const recordsY = await this.selectRangeByLocationsItems(dY, locationsItemsY, minDate, maxDate, this.scale);
              locations.forEach((location) => {
                // const name = `${location} (${iX}, ${iY})`;
                const name = `${location}`;
                const locationItemX = location + '-' + iX;
                const locationItemY = location + '-' + iY;
                const arrObjectsX = recordsX[locationItemX];
                const x = arrObjectsX ? arrObjectsX : [];
                const arrObjectsY = recordsY[locationItemY];
                const y = arrObjectsY ? arrObjectsY : [];
                vData = vData.concat(y);
                const visible = this.selectedGroup && Object.keys(this.selectedGroup).length !== 0 && !this.selectedGroup.items.includes(location) ? 'legendonly' : true;
                symbol = this.typeSelected == 'lines' || this.markerColor.length <= 0 ? null : this.markerSymbol[Math.floor(traceCount / this.markerColor.length) % this.markerSymbol.length];
                data.push({ name, visible, x, y, mode: this.typeSelected, hovertemplate: `(x:%{x}, y:%{y})`, line: { width: lineWidth }, marker: { size: markerSize, symbol: symbol, color: this.markerColor[traceCount % this.markerColor.length], line: oulineMarker } });
                traceCount++;
              });
            } else {
              // x- value , y -time
              this.isAxisTime = true;
              const locationsItemsX = _.map(locations, (l) => l + '-' + iX);
              const recordsX = await this.selectRangeByLocationsItems(dX, locationsItemsX, minDate, maxDate, this.scale);
              const y = recordsX.dates;
              locations.forEach((location) => {
                const locationItemX = location + '-' + iX;
                const arrObjectsX = recordsX[locationItemX];
                const x = arrObjectsX ? arrObjectsX : [];
                vData = vData.concat(x);
                const visible = this.selectedGroup && Object.keys(this.selectedGroup).length !== 0 && !this.selectedGroup.items.includes(location) ? 'legendonly' : true;
                symbol = this.typeSelected == 'lines' || this.markerColor.length <= 0 ? null : this.markerSymbol[Math.floor(traceCount / this.markerColor.length) % this.markerSymbol.length];
                data.push({ name: locationItemX, visible, x, y, mode: this.typeSelected, hovertemplate: `(x:%{x}, y:%{y})`, line: { width: lineWidth }, marker: { size: markerSize, symbol: symbol, color: this.markerColor[traceCount % this.markerColor.length], line: oulineMarker } });
                traceCount++;
              });
            }
          }
        }
        this.chartData = vData;
        return data;
      } catch {}
    },
    async getLayoutAndData() {
      try {
        let layout = null;
        layout = {
          legend: { itemsizing: 'constant', itemclick: true, itemdoubleclick: true },
          showlegend: this.showLegend,
          yaxis: {
            autorange: this.rotateChart ? 'reversed' : true,
          },
        };
        let minX = Infinity;
        let minY = Infinity;
        let maxX = -Infinity;
        let maxY = -Infinity;
        for (const { dX, iX, dY, iY, locations } of this.datasourceExtended) {
          if (iX !== 'TIME  ⏰' && iY !== 'TIME  ⏰') {
            const datasourceX = this.datasources.find((d) => d.id === dX);
            const datasourceY = this.datasources.find((d) => d.id === dY);
            if (datasourceX.storage_mode === 'FILE') {
              minX = Math.min(minX, this.generateScale(this.$db[dX].items[iX].min, this.$db[dX].items[iX], this.scale));
              maxX = Math.max(maxX, this.generateScale(this.$db[dX].items[iX].max, this.$db[dX].items[iX], this.scale));
            } else {
              const locationsItemsX = locations.map((l) => l + '-' + iX);
              let recordsX = await this.selectAllByLocationsItems(dX, locationsItemsX, this.scale);
              let recordsXValue = Object.values(recordsX);
              recordsXValue.shift();
              minX = Math.min(minX, _.min([].concat(...recordsXValue)));
              maxX = Math.max(maxX, _.max([].concat(...recordsXValue)));
            }
            if (datasourceY.storage_mode === 'FILE') {
              minY = Math.min(minY, this.generateScale(this.$db[dY].items[iY].min, this.$db[dY].items[iY], this.scale));
              maxY = Math.max(maxY, this.generateScale(this.$db[dY].items[iY].max, this.$db[dY].items[iY], this.scale));
            } else {
              const locationsItemsY = locations.map((l) => l + '-' + iY);
              let recordsY = await this.selectAllByLocationsItems(dY, locationsItemsY, this.scale);
              let recordsYValue = Object.values(recordsY);
              //remove column date
              recordsYValue.shift();
              minY = Math.min(minY, _.min([].concat(...recordsYValue)));
              maxY = Math.max(maxY, _.max([].concat(...recordsYValue)));
            }
          }
        }
        if (minX !== Infinity) {
          let marginX = (maxX - minX) / 20;
          let marginY = (maxY - minY) / 20;
          let layoutTemp = {
            xaxis: {
              range: [minX - marginX, maxX + marginX],
            },
            yaxis: {
              range: [minY - marginY, maxY + marginY],
            },
          };
          layout = _.merge(layout, layoutTemp);
        }
        let data = await this.getData();
        return { data, layout };
      } catch {}
    },
    async getDataCSV() {
      let dataCSV = '';
      let id = 'ID';
      let item = 'ITEM';
      let unit = 'UNIT';
      let dataLines = [];
      let lineCSV = '';
      let recordsDate = [];
      let dataTemp = [];
      const minDate = this.date.length === 2 ? this.date[0] : this.date.length === 1 ? '0000-00-00 00:00:00' : '';
      const maxDate = this.date.length === 2 ? this.date[1] : this.date.length === 1 ? this.date[0] : '';
      let dataUnitTemp = {};

      for (const { dX, iX, dY, iY, locations } of this.datasourceExtended) {
        try {
          let unitTempX = '';
          try {
            unitTempX = `${this.$db[dX].items[iX].unit}`;
          } catch {}
          let unitTempY = '';
          try {
            unitTempY = `${this.$db[dY].items[iY].unit}`;
          } catch {}
          for (let h = 0; h < locations.length; h++) {
            dataUnitTemp[`${locations[h]}-${iX}`] = unitTempX;
            dataUnitTemp[`${locations[h]}-${iY}`] = unitTempY;
          }
        } catch {}

        this.initFirstTime = false;
        if (iX === 'TIME  ⏰') {
          //  x - timer , y-value
          if (iY !== 'TIME  ⏰') {
            const locationsItemsY = _.map(locations, (l) => l + '-' + iY);
            const recordsY = await this.selectRangeByLocationsItems(dY, locationsItemsY, minDate, maxDate, this.scale);
            dataTemp.push(recordsY);
          } else {
            //  x - timer , y-timer
            if (dX.length > 0) {
              for (let intdX = 0; intdX < dX.length; intdX++) {
                const locationsItemsX = _.map(locations[intdX], (l) => l + '-' + dX[intdX]);
                const recordsDate = await this.selectRangeByLocationsItems(dY[intdX], locationsItemsX, minDate, maxDate, this.scale);
                dataTemp.push({ dates: recordsDate.dates });
              }
            }
          }
        } else {
          if (iY !== 'TIME  ⏰') {
            //  x - value , y-value
            const locationsItemsX = _.map(locations, (l) => l + '-' + iX);
            const recordsX = await this.selectRangeByLocationsItems(dX, locationsItemsX, minDate, maxDate, this.scale);
            const locationsItemsY = _.map(locations, (l) => l + '-' + iY);
            const recordsY = await this.selectRangeByLocationsItems(dY, locationsItemsY, minDate, maxDate, this.scale);
            dataTemp.push(recordsX);
            dataTemp.push(recordsY);
          } else {
            // x- value , y -time
            const locationsItemsX = _.map(locations, (l) => l + '-' + iX);
            const recordsX = await this.selectRangeByLocationsItems(dX, locationsItemsX, minDate, maxDate, this.scale);
            dataTemp.push(recordsX);
          }
        }
      }
      dataTemp.forEach((records) => {
        if (records.dates && records.dates.length > 0) {
          recordsDate = recordsDate[0] && new Date(recordsDate[0]) > new Date(records.dates[0]) ? [...new Set([...records.dates, ...recordsDate])] : [...new Set([...recordsDate, ...records.dates])];
        }
        for (let key in records) {
          if (key != 'dates') {
            let keySplit = key.split('-');
            let localtionTemp = keySplit[0];
            let itemTemp = keySplit[1] ? keySplit[1] : '';
            let unitTemp = dataUnitTemp && dataUnitTemp[key] ? dataUnitTemp[key] : '';
            id += ',' + localtionTemp;
            item += ',' + itemTemp;
            unit += `,${unitTemp}`;
          }
        }
      });
      dataTemp.forEach((records) => {
        recordsDate.forEach((date, index) => {
          dataLines[index] = dataLines[index] && dataLines[index] != '' ? dataLines[index] : date;
          for (let key in records) {
            if (key != 'dates') {
              let idt = records.dates.indexOf(date);
              if (idt != -1) {
                dataLines[index] += ',' + records[key][idt];
              } else {
                dataLines[index] += ',';
              }
            }
          }
        });
      });
      dataLines.forEach((line) => {
        lineCSV += line + '\r\n';
      });
      if (lineCSV != '') {
        dataCSV += id + '\r\n' + item + '\r\n' + unit + '\r\n' + lineCSV;
      }
      return dataCSV;
    },
    downloadHtml() {
      this.$refs.chart.asHtml(this.tempProject.name + '_Line');
    },
    downloadImage() {
      this.$refs.chart.asImage(this.tempProject.name + '_Line');
    },
    async downloadCsv() {
      let data = await this.getDataCSV();
      this.$refs.chart.asCSV(this.tempProject.name + '_Line', data);
    },
    generateScale(value, item, scale) {
      if (scale === 'log10') {
        value = scaleValueLog10(value);
      } else if (scale === 'log10+') {
        value = scaleValueLog10Plus(value);
      } else if (scale === 'normalize') {
        value = scaleValueNormalize(value, item.min, item.max);
      } else if (scale === 'standardize') {
        value = scaleValueStandardize(value, item.mean, item.std);
      } else if (scale === 'live') {
        value = value;
      }
      return value;
    },
    changeColorAlpha(color, opacity) {
      if (color.length > 7) color = color.substring(0, color.length - 2);
      const _opacity = Math.round(Math.min(Math.max(opacity, 0), 1) * 255);
      let opacityHex = _opacity.toString(16).toUpperCase();
      // opacities near 0 need a trailing 0
      if (opacityHex.length == 1) opacityHex = '0' + opacityHex;
      return color + opacityHex;
    },
  },
};
</script>
